export const API_URL = 'https://dev.em.techfunder.de';
export const BRN_URL = 'https://api.tapcat.fun';
// export const BOT_ID = 'KY24L16X12'; //realty
export const BOT_ID = 'EDRDEZSGXH';
export const MERCHANT_ID = '6UZG2TW3DQ';
export const TASK_ID = '66cf675fd4f11bee58360e3e';

export const SlEEP_TIME = 0.3 * 60 * 1000;
export const ACTIVE_TIME = 0.6 * 60 * 1000;


export const APP_VERSION = '1.0.5';

export const LEADERBOARD_KEY = '679e0f57d7a9340f1ddeb651';
export const COLLECTION_ID = 'W4XN3YDAPR';

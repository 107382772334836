import { createSlice } from '@reduxjs/toolkit'

export const bgSlice = createSlice({
    name: 'background',
    initialState: {
        background: ''
    },
    reducers: {
        setBackground: (state, action) => {
            state.background = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    setBackground
} = bgSlice.actions

export default bgSlice.reducer

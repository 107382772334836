import React, { useState } from 'react';

const colors = [
  "#0C266F",
  "#42933F",
  "#F1D937",
  "#3E9DEE",
  "#80d397",
  "#B190B6",
  "#8AEDFF",
  "#FE9922"];

const getColorFromName = (name) => {
  if (!name) {
    return '#B190B6';
  }
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  return colors[Math.abs(hash) % colors.length];
};
const ImageComponent = ({ src, alt, imgClass, name }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [err, SetImgErr] = useState(false);


  const bgColor = getColorFromName(name);
  const handleError = () => {
    SetImgErr(true);
  };
  const firstLetter = name ? name.charAt(0).toUpperCase() : "?";

  return (
      imgSrc && !err ? <img
      className={imgClass}
      src={imgSrc}
      alt={alt}
      onError={handleError}
    /> :
    <div className={'person-icon'}
         style={{backgroundColor: bgColor}}>{firstLetter}</div>
  );
};

export default ImageComponent;

import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import FooterFunction from "../../components/app/functions/FooterFunction";
import React, {useEffect, useState} from "react";
import {ReactComponent as PersonIcon} from '../../assets/person-img.svg';
import {
    claimPoints,
    getAffiliates,
    getEarnings,
    getLeaderboard,
    getLoyaltyData, getRandomElement,
    getReferrals,
    getTasks
} from "../../logic/server/api";
import {PATH_CLAIM, PATH_PROFILE} from "../../constants/Paths";
import {useNavigate} from "react-router-dom";
import person1 from "../../assets/person1.png";
import ImageComponent from "../../components/app/functions/ImageComponent";
import person2 from "../../assets/person2.png";
import person3 from "../../assets/person3.png";
import person4 from "../../assets/person3.png";
import person5 from "../../assets/person5.png";
import person6 from "../../assets/person6.png";
import person7 from "../../assets/person7.png";
const CommunityPage = () => {
    const [affiliates, setAffiliates] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getAffiliatesList();
        getAffiliatesData();
    }, []);
    const points = localStorage.getItem('points');
    const persons = [person1, person2, person3, person4, person5, person6, person7];

    const copyLink =  `https://t.me/share/url?url=https://t.me/PZN_DEV_BOT/game&text=I have ${parseNumbers(points)} DINKS! Join Picklezone Game!`;
    async function getAffiliatesList() {
        const resp = await getLeaderboard();
        if (resp?.status) {
            setAffiliates(resp?.data?.entries);
        }
    }

    async function getAffiliatesData() {
        const customerId = localStorage.getItem('customer');
        const token = localStorage.getItem('token');
        if (customerId && token) {
            const resp = await getLoyaltyData(customerId, token);
            if (resp && resp?.data?.reward) {
                const points = resp?.data?.reward?.loyalty_points;
                localStorage.setItem('code', resp?.data?.referral_code);
                // dispatch(setAmount(points));
                localStorage.setItem('points', points);
            }
        }
    }

    async function onCopyLink() {
       // await navigator.clipboard.writeText(copyLink);
       await window.open(copyLink, '_blank');
    }

    function parseNumbers(points) {
        if (points / 1000000 > 10) {
           return `${points / 1000000}M`;
        } else if (points / 1000 > 10) {
           return `${points / 1000}K`;
       }  else {
            return points;
        }
    }




    return (
        <TelegramScreen
            className={`main-wrapper white-background`}  id='gesuredZone'>
            <div className='picklezone-container picklezone-container-dim'>
                <div className={'cards-container--top'}>
                    <div className={'card__tasks--title'}>
                        <p className={'text-bold-roboto'}><span style={{fontWeight: '900'}}>Top</span> Players</p>
                    </div>
                    <div className={'cards__container--community'}>
                        <div className='friends-card'>
                            <p className='friends-card--title'>worldwide</p>
                            {/*<p className='friends-card--description'>Registered with your referral link</p>*/}
                        </div>
                        <div className={'container-scrollable'}>
                        {affiliates?.map((affiliate, i) => (
                            <div className={'earning-card__task'}
                                 key={affiliate?.score}
                            >
                               <div className={'earning-card-block'}> 
                                {/*<PersonIcon/>*/}
                                <ImageComponent
                                    imgClass={'earning-card__img'}
                                    alt={'person'}
                                    src={affiliate?.image}
                                    name={affiliate?.name}
                                ></ImageComponent>
                                <div className={'card__column-to-row community'}>
                                <p className={'text-name-roboto'}>{affiliate?.name}</p>
                                    {/*<div className={'card__column-to-row'} style={{marginLeft: 'auto'}}>*/}
                                        <p className={'text-title-roboto'}>{parseNumbers(affiliate?.score)} DINKS</p>
                                    {/*</div>*/}
                                </div>
                               </div>

                                <div className={'card__column-to-row'} style={{marginLeft: '10px'}}>
                                    <p className={`blue-text-name-roboto ${i < 3 ? 'accent' : ''}`}>#{i + 1}</p>
                                </div>

                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className='forms__bottom'>
                    
                 <div className={'picklezone-forms-wrapper'}>
                    {/*<div className={'picklezone-forms-container'}>*/}
                    {/*    /!*<div className={'blue-text-name-roboto profile-link'}*!/*/}
                    {/*    /!*     onClick={() => navigate(PATH_PROFILE)}>My profile</div>*!/*/}
                    {/*    <button className={'btn-for-copy-links'}*/}
                    {/*        onClick={() => onCopyLink()}>*/}
                    {/*        Share your success!</button></div>*/}
                    <FooterFunction currentPage={'community'} />
                    </div>
                </div>
            </div>
        </TelegramScreen>)


}

export default CommunityPage;

import React, {useEffect, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
// import {useTelegram} from "../../hooks/useTelegram";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {ReactComponent as UserIcon} from '../../assets/solar_users-group-rounded-bold.svg';
import {ReactComponent as VideoIcon} from '../../assets/solar_video-library-bold.svg';
import {ReactComponent as ArrowIcon} from '../../assets/angle-right-1.svg';
import {ReactComponent as CheckIcon} from '../../assets/check-circle.svg';
import {ReactComponent as GameIcon} from '../../assets/gamepanel-icon.svg';
import {ReactComponent as GroupIcon} from '../../assets/group-icon.svg';
import {claimPoints, getTasks, setInteraction} from "../../logic/server/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTelegram} from "../../hooks/useTelegram";

const TasksPage = () => {
    const {webApp, user} = useTelegram();
    // const [tasks, setTasks] = useState([]);
    const [tasksFollowPartner, setPartnersTasks] = useState([]);
    const [tasksFollowChannel, setChannelTasks] = useState([]);
    const [tasksDaily, setDailyTasks] = useState([]);
    const [linkOpened, setLinkOpened] = useState([]);
    const [taskClaimed, setTaskClaimed] = useState([]);
    const [time, setTime] =  useState();
    const [dailyTaskStatus, setDailyTaskStatus] = useState(false);

    // active time timer
    useEffect(() => {
        let interval;
        if ( time && time > 0) {
            interval = setInterval(() => {
                localStorage.setItem('time', (time - 10).toString());
                setTime((prevTimer1) => prevTimer1 && (prevTimer1 - 10));
            }, 10);
        } else if (time === 0 ) {
            setDailyTaskStatus(true);
        }
        return () => clearInterval(interval);

    }, [time]);

    useEffect(() => {
        getTasksRequest();
    }, []);

    useEffect(() => {
        getTasksRequest();
    }, [linkOpened, taskClaimed]);

    async function getTasksRequest() {
        const resp = await getTasks();
        const followChannel = resp?.tasks?.filter(item => item?.conditions[0]?.type === 'interaction-tracker.link-visited').
            filter(task => task?.completion.status !== 'completed');
        setChannelTasks(followChannel?.length ? followChannel : []);
        const dailyTask = resp?.tasks?.filter(item => item?.description.toLowerCase() === 'daily reward');
        setDailyTasks(dailyTask);
        if (dailyTask?.length) {
            if (!dailyTask[0]?.completion?.limits?.cooldown) {
                setTime(0);
                setDailyTaskStatus(true);
                return;
            }
            const remainingTime = dailyTask[0]?.completion?.limits?.cooldown?.remaining;
            setTime(remainingTime);
            setDailyTaskStatus(!remainingTime);
        }
    }

   async function handleInteraction(task) {
        if (task?.conditions[0].data?.link && (!task?.completion.claimable && task?.completion.status !== 'completed' )) {
            // console.log(task);
            const res = await setInteraction(task?.conditions[0].data?.link);
            // window.open(task?.conditions[0].data?.link, '_blank');
            // window.location.assign(task?.conditions[0].data?.link);
            webApp.openLink(task?.conditions[0].data?.link);

            // window.location.href = task?.conditions[0].data?.link;
           if (res?.status) {
               setLinkOpened([{id: task.id, status: true}]);
           }
        } else if (task?.completion.claimable) {
            handleClaim(task);
            toast.success(`You claimed ${task?.reward?.data?.value} DINKS successfully!`);
        }
    }

    // Format time into mm:ss:ms format
    const formatTime = (timeInMs) => {
        const hours = Math.floor(timeInMs / (60 * 60 * 1000));
        const minutes = Math.floor((timeInMs % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((timeInMs % (60 * 1000)) / 1000);
        const milliseconds = Math.floor((timeInMs % 1000) / 10); // Show only two digits for ms

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    async function handleClaim(task) {
        const res = await claimPoints( localStorage.getItem('token'), task.id);
        if (res?.status) {
            setTaskClaimed([{id: task.id, status: true}]);
        }
    }

        return (
        <TelegramScreen
            className={`main-wrapper white-background`}  id='gesuredZone'>
            <div className='picklezone-container picklezone-container-dim'>
                <div className={'cards-container--top'}>
                <div className={'card__tasks--title '}>
                    
                    <p className={'text-bold-roboto'}><span style={{fontWeight: 900}}>{[...tasksFollowChannel, ...tasksFollowPartner, ...tasksDaily].length > 1 ? 'Tasks' : 'Task'}</span> Available</p>
                </div>
                {/*<div className={'cards__container cards__container '}>*/}
                    {tasksDaily.map((task) => (
                        <div className={'task-card'}
                             key={task}
                        >
                           <div className={'picklezone-card__row'}>
                               <GameIcon/>
                               <div className={'picklezone-card__column-to-row'}>
                                   <div className={'picklezone-card__task--text'}>
                                       {task?.title}</div>
                                   <div >
                                       {dailyTaskStatus && <div className={'picklezone-card__timer'}>{task?.reward?.data?.value} DINKS</div>}
                                       {/*{!dailyTaskStatus && <div className={'picklezone-card__text'}>Available soon {formatTime(time)}</div>}*/}
                                   </div>
                               </div>
                           </div>

                            {/*{dailyTaskStatus && <div className={'picklezone-claim-row'}>*/}
                            {/*    <UserIcon/>*/}
                            {/*    <p className={'claim-text-card daily'}*/}
                            {/*       onClick={() => handleInteraction(task)}>CLAIM NOW</p>*/}
                            {/*</div>}*/}
                            {dailyTaskStatus && <button className={'btn-for-copy-links in-form'}
                                    onClick={() => handleInteraction(task)}>
                                Collect today’s reward</button>}
                            {!dailyTaskStatus && <button className={'btn-for-copy-links in-form gray'}>
                                Available soon {formatTime(time)}</button>}
                        </div>
                    ))}
                    {tasksFollowChannel?.length ?
                        <div className={'task-card task-card-scrollable'}>
                            {tasksFollowChannel.map((task) => (
                        <div className={'picklezone-card__row'}>
                        <GroupIcon />
                        <div className={`new-picklezone-card__task ${task?.completion.status === 'completed' ?  'disabled--bright' :''}`}
                         onClick={() => handleInteraction(task)}>
                        <div className={'picklezone-card__column-to-row'}>
                            <p className={'picklezone-card__task--text'}>{task?.title}</p>
                            <p className={'small-card-task-text'}>{task?.reward?.data?.value} DINKS for task</p>
                        </div>

                       {task?.completion.claimable && <div className={'picklezone-claim-row'}>
                                <p className={'claim-text-card'}>CLAIM <br/> NOW</p>
                       </div>}
                        {(!task?.completion.claimable && task?.completion.status !== 'completed') &&
                            <ArrowIcon style={{width: '20px', height: '20px'}}></ArrowIcon>}
                        {(task?.completion.status === 'completed') &&
                            <CheckIcon  style={{width: '20px', height: '20px'}}></CheckIcon>}
                    </div>
                        </div>
                    ))}
                 </div> : ''}
                {/*</div>*/}
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'tasks'}/>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>)

}
export default TasksPage;

import React from "react";
import InfoBtn2 from '../../../assets/question-3.png';
import InfoBtn from '../../../assets/help-sign.png';
import Info1 from '../../../assets/info-page/rule-1.png';
import Info2 from '../../../assets/info-page/rule-2.png';
import Info3 from '../../../assets/info-page/rule-3.png';
import Info4 from '../../../assets/info-page/rule-4.png';
import Info5 from '../../../assets/info-page/rule-5.png';
import Info6 from '../../../assets/info-page/rule-6.png';
import Info7 from '../../../assets/info-page/rule-7.png';
import {useState} from "react";
import CloseIcon from "../../../assets/close-circle-white.svg";

const InfoScreen = ({onVisibilityChange, isVisibleStatus }) => {
    const [isVisible, setVisibleState] = useState(false);
    if (isVisibleStatus !== isVisible) {
        setVisibleState(isVisibleStatus);
    }
   // setVisibleState(isVisibleStatus);
    function togglePanel() {
        const newVisibility = !isVisible;
        setVisibleState(newVisibility);

        if (onVisibilityChange) {
            onVisibilityChange(newVisibility);
        }
    }
    return (
        <div style={{zIndex: 1000}} >
            {/*<div className='info-page-button' onClick={() => togglePanel()}>*/}
            {/*    <img src={InfoBtn}/>*/}
            {/*</div>*/}
            {/*<img src={InfoBtn4} className='info-page-button'*/}
            {/*     onClick={() => togglePanel()}/>*/}
            <div className={`info-page ${isVisible ? 'visible' : ''}`}>
                <img className={'info-page--close-button'}
                    src={CloseIcon} onClick={() => togglePanel()}/>

                <div className={'info-page--container'}>
                    <p className={'info-page--title'}>Rules</p>
                    <div className={'info-page__card'}>
                        <img src={Info1} className={'info-page--img'}/>
                        <p className={'info-page--text'}>Tap the balls to earn Dinks.</p>
                    </div>
                 <div className={'info-page__card'}>
                     <div className={'info-page--img-container'}>
                         <img src={Info2} className={'info-page--img'}/>
                         {/*<img src={Info3} className={'info-page--img'}/>*/}
                     </div>
                     <p className={'info-page--text'}> Orange balls bring additional rewards, don't miss them!</p>
                 </div>
                    <div className={'info-page__card'}>
                        <img src={Info4} className={'info-page--img'}/>
                        <p className={'info-page--text'}>Complete tasks to earn additional Dinks.</p>

                    </div>
                    <div className={'info-page__card'}>
                        <img src={Info5} className={'info-page--img'}/>
                        <p className={'info-page--text'}>Invite friends to earn even more.</p>
                    </div>
                    <div className={'info-page__card'}>
                        <img src={Info6} className={'info-page--img'}/>
                        <p className={'info-page--text'}>Play time is limited, but resets every X minutes.</p>
                    </div>
                    <div className={'info-page__card'}>
                        <img src={Info7} className={'info-page--img'}/>
                        <p className={'info-page--text'}>Dinks can later be redeemed for crypto tokens.</p>
                    </div>
                </div>
                <button className={'btn-for-copy-links in-form'}
                        onClick={() => togglePanel()}>Ok</button>
            </div>

        </div>

    )
}
export default InfoScreen;

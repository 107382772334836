import React from "react";
import Main from "../../../screens/main/MainScreen";
import {useSelector} from "react-redux";
import {ReactComponent as InfoIcon} from '../../../assets/info-top.svg';

const HeaderFunction = ({onShowInfo}) => {
    const count = useSelector(state => state.counter.count);

    return (
                    <div className={`forms__top`}>
                        <div className={'forms__top-container'} onClick={onShowInfo}>
                            <InfoIcon className={'forms_top__icon'}></InfoIcon>
                            <p className={`text-bold forms__top-text`}>Total Score</p>
                        </div>
                            <p className={`text-bold forms__text-score`}>{count}</p>
                        {/*<a className={`text-medium forms__top-text`}*/}
                        {/*   target="_blank" rel="noreferrer"*/}
                        {/*>Total score {count}</a>*/}
                    </div>
                )
}
export default HeaderFunction;
